.login-box {
    display: block;
    width: 300px;
    margin: 35vh auto;
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 5px 5px 10px 0px rgba(0,0,0,0.20);

    &--header {
        text-align: center;
        font-size: 22px;
       // font-weight: bold;
        margin-bottom: 20px;
        display: block;
    }
}

.table-container {
    min-height: 400px;
}

.notice-green {
    color: green;
}

.notice-red {
    color: red;
}

.strike {
    text-decoration: line-through;
}

.notifiction-link {
    color: white;
    text-decoration: underline;

    &:hover {
        color: whitesmoke;
        text-decoration: underline;   
    }
}

.card {
    &.no-border {
        border: none;
    }
    
    .card-body.tight {
        padding: 0.5rem;
    }
}


.col-max-200 {
    max-width: 200px;
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.col-max-80 {
    max-width: 80px;
    width: 80px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.text-center {
    text-align: center;
}

.section-header {
    border-bottom: 2px solid;
    margin-bottom: 10px;

    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 0;
    }
}

.pointerCursor:hover {
    cursor: pointer;
}