/*******************
 General Topbar
*******************/

.topbar {
  position: relative;
  z-index: 50;
  transition: $transitions;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.05);
  background: $topbar;
  height: $topbar-height;
  .navbar-collapse {
    padding: 0 10px 0 0;
  }
  .top-navbar {
    min-height: $topbar-height;
    padding: 0px;
    .dropdown-toggle::after {
      display: none;
    }
    .navbar-header {
      line-height: $topbar-navlink-height;
      .navbar-brand {
        display: flex;
        align-items: center;
        margin: 0px;
        padding: $topbar-navbrand-padding;
        // .dark-logo {
        //     display: none;
        // }
      }
    }
    .navbar-nav > .nav-item > .nav-link {
      padding: $topbar-navlink-padding;
      font-size: $topbar-navlink-font-size;
      line-height: $topbar-navlink-height;
      height: $topbar-navlink-height;
    }
    .navbar-nav > .nav-item {
      transition: 0.2s ease-in;
      &:hover {
        background: $transparent-dark-bg;
      }
    }
  }
  .user-dd {
    min-width: 280px;
  }
  .nav-toggler {
    color: rgba(0, 0, 0, 0.9); //$theme-light;
    padding: 0 15px;
  }
}

.search-box {
  .app-search {
    z-index: 110;
    width: 100%;
    top: -1px;
    box-shadow: $shadow;
    display: none;
    left: 0px;
    &.show-search {
      display: block;
    }
    .form-control {
      padding: 32px 40px 32px 23px;
      @extend %square;
      transition: $transitions;
      &:focus {
        border-color: transparent;
      }
    }
    .srh-btn {
      position: absolute;
      top: 23px;
      cursor: pointer;
      right: 20px;
    }
  }
}

/*******************
Designed dropdown
******************/
.topbar {
  .dropdown-menu {
    padding-top: 0px;
    border: 0px;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
    &.dropdown-menu-right {
      .with-arrow {
        right: 0px;
        > span {
          right: 20px;
          left: auto;
        }
      }
    }
  }
}
/*******************
Topbar Responsive
******************/

@media (max-width: 767px) {



  .topbar .top-navbar {
    
    .navbar-collapse {
      padding: 0 0 0 0;
    }

    .navbar-collapse.show,
    .navbar-collapse.collapsing {
      display: block;
      width: 100%;
      border-top: 1px solid $border-color;
    }
    .navbar-nav {
      flex-direction: row;
      .dropdown {
        position: static;
      }
    }
    .navbar-nav > .nav-item > .nav-link {
      padding: 0 10px;
    }
    .navbar-header {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
    }
    .dropdown-menu {
      position: absolute;
      width: 100%;
      .with-arrow {
        display: none;
      }
    }
  }
}
