// Responsive
/*
Template Name: Admin Template
Author: Wrappixel

File: scss
*/


@media (max-width: 1169px) and (min-width: 768px) {
	.upgrade-btn {
		display: none;
	}
}

/*-- ============================================================== 
 Ipad & above all(768px) 
 ============================================================== */

@include media-breakpoint-up(md) {
    .bc-content{
        justify-content: flex-end;
    }
}

/*-- ============================================================== 
 Phone and below ipad(767px) 
 ============================================================== */
@include media-breakpoint-down(md) {
    .do-block{
        display: block!important;
    }
}