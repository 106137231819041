// Variables (Required file)
@import "variable";
@import "../../../node_modules/bootstrap/scss/mixins";

//This is for the components (Required file)
@import "components";

//This is for the responsive (Required file)
@import "responsive";

//In This scss you can write your scss
@import "custom";

//This is for the icons (Required file)
@import "icons/font-awesome/css/fontawesome-all.css";
@import "icons/themify-icons/themify-icons.css";
@import "icons/material-design-iconic-font/css/materialdesignicons.min.css";
@import "icons/weather-icons/css/weather-icons.min.css";

//This is for angular Material
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
